import { AccountSettingInterface, UserInfo, UserPermissionInterface } from "src/types/auth/auth";
import { TOKEN_KEY, USER_ACCOUNT_SETTING_KEY, USER_KEY, USER_PERMISSION_KEY } from "src/utils/constants/keys";
import Cookies from 'universal-cookie';

const cookies = new Cookies(null, { path: '/' });

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null ? true : false;
export const getToken = () => JSON.parse(localStorage.getItem(TOKEN_KEY)!);
export const createUserSession = (value:string, key:string, duration:number) => cookies.set(key, value, { maxAge: duration, });
export const getUserSession = (key:string) => cookies.get(key);
export const logOut = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER_PERMISSION_KEY);
  localStorage.removeItem(USER_ACCOUNT_SETTING_KEY);
  localStorage.removeItem(USER_KEY);
};
export async function getUserInfo() {
  let userInfo:UserInfo
  userInfo = {
    email:'', 
    id: 0, 
    is_staff: false, 
    is_superuser: false, 
    username:'', 
    first_name:'', 
    last_name:'', 
    full_name:'', 
    contact:'', 
    profile_image:'', 
    address:{
      bairro:'',
      bairro_code:'',
      id:0,
      municipio:'',
      municipio_code:'',
      province:{
        code:'',
        description:''
      }
    }, 
    bi:'',
    is_active: false}
  const struser = await localStorage.getItem(USER_KEY)
  if (struser !== null) userInfo =  JSON.parse(struser)
    
  return userInfo
}

export async function getUserPermission() {
  let userPerssion:UserPermissionInterface
  userPerssion = {permission:'', page_module:[{id:0, module:'', description:'', type:''}], municipio:undefined}
  const struser = await localStorage.getItem(USER_PERMISSION_KEY)
  if (struser !== null) userPerssion =  JSON.parse(struser)

  return userPerssion
}

export function getAccountTheme() {
  let accountSettings:AccountSettingInterface
  accountSettings = {
    device:{
      browser:'',
      device_id:'',
      ip_address:''
    },
    theme:'light'
  }
  const struser = localStorage.getItem(USER_ACCOUNT_SETTING_KEY)
  if (struser !== null) accountSettings =  JSON.parse(struser)
    
  return accountSettings.theme
}

import { SpecialistAvaliationDetailsInterface } from 'src/types/management/tools';

export const SPECIALIST_INIT_VALUE = {
  id: 0,
  code: '',
  address: {
    bairro: '',
    bairro_code: '',
    id: 0,
    municipio: '',
    municipio_code: '',
    province: {
      code: '',
      description: '',
    },
  },
  description: '',
  educational_instituition: {
    code: '',
    description: '',
    id: 0,
  },
  email: '',
  formation_area: [],
  is_company: false,
  municipality_work: {
    code: '',
    description: '',
  },
  phone_number: '',
  specialist_type: {
    code: '',
    description: '',
  },
  specialization: [],
  status: '',
  supervisor: {
    email: '',
    full_name: '',
    id: 0,
    municipio: {
      code: '',
      description: '',
    },
    phone: '',
    status: '',
  },
  supervisor_full_name: '',
  supervisor_phone: '',
};

export const SPECIALIST_AVALIATION_VALUE: SpecialistAvaliationDetailsInterface = {
  avaliation_details: {
    avaliation_id: 0,
    correct_question: '',
    course_id: 0,
    end_date: '',
    id: 0,
    score: '',
    specialist_id: 0,
    total_questions: '',
    wrong_question: '',
  },
  questions: [
    {
      answer_option: [
        {
          answer_value: 0,
          option_description: '',
          question_id: 0,
          quiz_id: 0,
          choisen_option: false,
        },
      ],
      question_description: '',
      question_is_correct: false,
    },
  ],
};

export const USER_INFO_INIT_VALUE = {
  email: '',
  id: 0,
  is_staff: false,
  is_superuser: false,
  username: '',
  first_name: '',
  last_name: '',
  full_name: '',
  contact: '',
  profile_image: '',
  is_active: false,
  address: {
    bairro: '',
    bairro_code: '',
    id: 0,
    municipio: '',
    municipio_code: '',
    province: {
      code: '',
      description: '',
    },
  },
  bi: '',
};

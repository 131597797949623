import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import ProvincePage from 'src/views/pages/admin/tools/Provinces';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

/* ****Pages***** */
const ModernDash = Loadable(lazy(() => import('../views/dashboard/Modern')));
const EcommerceDash = Loadable(lazy(() => import('../views/dashboard/Ecommerce')));
const FormationAreaPage = Loadable(lazy(() => import('../views/pages/admin/tools/Area')));
const EspecializationPage = Loadable(lazy(() => import('../views/pages/admin/tools/Especialization')));
const SpecialistRegisterPage = Loadable(lazy(() => import('../views/pages/specialist/Register')));
const MunicipalyListPage = Loadable(lazy(() => import('../views/pages/admin/tools/Municipio')));
const SpecializationTypePage = Loadable(lazy(() => import('../views/pages/admin/tools/SpecializationType')));
const ListSupervisorPage = Loadable(lazy(() => import('../views/pages/accounts/supervisor/List')));
const SupervisorRegistrationPage = Loadable(lazy(() => import('../views/pages/accounts/supervisor/Register')));
const SupervisorEditPage = Loadable(lazy(() => import('../views/pages/accounts/supervisor/Edit')));
const NecessityRegistration = Loadable(lazy(() => import('../views/pages/municipio/necessity/Register')));
const NecessityTypePage = Loadable(lazy(() => import('../views/pages/municipio/admin/ListType')));
const SectorPage = Loadable(lazy(() => import('../views/pages/municipio/admin/Sector')));
const ListNecessity = Loadable(lazy(() => import('../views/pages/municipio/necessity/List')));
const MunicipalyUserRegistration = Loadable(lazy(() => import('../views/pages/municipio/user/Register')));
const MunicipalyUserResponsibilityPage = Loadable(lazy(() => import('../views/pages/municipio/user/Responsibility')));
const MunicipalyListUser = Loadable(lazy(() => import('../views/pages/municipio/user/List')));
const SpecialistUserSolicitationsPage = Loadable(lazy(() => import('../views/pages/specialist/user/solicitation')));
const CoursesPage = Loadable(lazy(() => import('../views/pages/admin/tools/Courses')));
const NecessityDetailsPage = Loadable(lazy(() => import('../views/pages/municipio/necessity/Details')));
const DetailedActiveNecessity = Loadable(lazy(() => import('../views/pages/municipio/necessity/DetailedActive')));
const EducationalInstutionPage = Loadable(lazy(() => import('../views/pages/admin/tools/EducationalInstitution')));
const QuizCreatorPage = Loadable(lazy(() => import('../views/pages/quiz/QuizCreator')));
const SpecialistPadingPage = Loadable(lazy(() => import('../views/pages/admin/specialist/SpecialistCandidacy')));
const AdminReviewSpecialistAvaliation = Loadable(lazy(() => import('../views/pages/admin/specialist/avaliation')));
const RatingPage = Loadable(lazy(() => import('../views/pages/Rating/Rate')));
const RegistartionUserPage = Loadable(lazy(() => import('../views/pages/admin/users/Registration')));
const AdminMunicipalyUserListPage = Loadable(lazy(() => import('../views/pages/admin/users/list')));
const SpecialistWorksPage = Loadable(lazy(() => import('../views/pages/specialist/user/works')));
const WorkDetailsPage = Loadable(lazy(() => import('../views/pages/specialist/user/workDetails')));
const AvaliationListPage = Loadable(lazy(() => import('../views/pages/quiz/avaliation/List')));
const AccountSetting = Loadable(
  lazy(() => import('../views/pages/account-setting/AccountSetting')),
);


/* ****Dashboards***** */
const MunicipioDashboardGeral = Loadable(lazy(() => import('../views/pages/dashboard/municipio/DashboardGeral')));


// authentication
const Login = Loadable(lazy(() => import('../views/authentication/auth1/Login')));
const Login2 = Loadable(lazy(() => import('../views/authentication/auth2/Login2')));
const Register = Loadable(lazy(() => import('../views/authentication/auth1/Register')));
const RegisterPreExistUserPage = Loadable(lazy(() => import('../views/authentication/auth1/RegisterPreExistUser')));
const Register2 = Loadable(lazy(() => import('../views/authentication/auth2/Register2')));
const AccountValidatePage = Loadable(lazy(() => import('../views/authentication/auth1/AccountValidate')));
const ForgotPassword = Loadable(lazy(() => import('../views/authentication/auth1/ForgotPassword')));
const ForgotPassword2 = Loadable(
  lazy(() => import('../views/authentication/auth2/ForgotPassword2')),
);
const OtpValidationPage = Loadable(lazy(() => import('../views/authentication/auth1/OtpValidation')));
const ResetPasswordPage = Loadable(lazy(() => import('../views/authentication/auth1/ResetPassword')));
const TwoSteps2 = Loadable(lazy(() => import('../views/authentication/auth2/TwoSteps2')));
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const AccountErrorProblem = Loadable(lazy(() => import('../views/authentication/AccountProblemError')));
const Maintenance = Loadable(lazy(() => import('../views/authentication/Maintenance')));
const ProjectSchedulePage = Loadable(lazy(() => import('../views/pages/specialist/user/projectSchedule')));


// landingpage
const Landingpage = Loadable(lazy(() => import('../views/pages/landingpage/Landingpage')));
const LadingPageMain = Loadable(lazy(() => import('../views/pages/landingpage/Landingpage1')));


const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <Navigate to="/landingpage" /> },
      { path: '/dashboards', exact: true, element: <ModernDash /> },
      { path: '/dashboards/municipio', exact: true, element: <MunicipioDashboardGeral /> },
      { path: '/tools/formation/area', exact: true, element: <FormationAreaPage /> },
      { path: '/tools/specialization', exact: true, element: <EspecializationPage /> },
      { path: '/tools/specialization/type', exact: true, element: <SpecializationTypePage /> },
      { path: '/dashboards/ecommerce', exact: true, element: <EcommerceDash /> },
      { path: '/specialist/register', exact: true, element: <SpecialistRegisterPage /> },
      { path: '/tools/municipio', exact: true, element: <MunicipalyListPage /> },
      { path: '/tools/course', element: <CoursesPage /> },
      { path: '/tools/province', element: <ProvincePage /> },
      { path: '/manager/supervisor/lista', exact: true, element: <ListSupervisorPage /> },
      { path: '/manager/supervisor/register', exact: true, element: <SupervisorRegistrationPage /> },
      { path: '/manager/supervisor/edit', exact: true, element: <SupervisorEditPage /> },
      { path: '/municipio/necessity/register', element: <NecessityRegistration /> },
      { path: '/municipio/admin/type', element: <NecessityTypePage /> },
      { path: '/municipio/admin/sector', element: <SectorPage /> },
      { path: '/municipio/admin/registe_user', element: <MunicipalyUserRegistration /> },
      { path: '/municipio/admin/responsibility', element: <MunicipalyUserResponsibilityPage /> },
      { path: '/municipio/admin/users', element: <MunicipalyListUser /> },
      { path: '/municipio/necessity/list', element: <ListNecessity /> },
      { path: '/municipio/necessity/deatils', element: <NecessityDetailsPage /> },
      { path: '/municipio/necessity/deatils/active', element: <DetailedActiveNecessity /> },
      { path: '/specialist/solicitations', element: <SpecialistUserSolicitationsPage /> },
      { path: '/specialist/candidacy', element: <SpecialistPadingPage /> },
      { path: '/tools/instituition', element: <EducationalInstutionPage /> },
      { path: '/quiz/creator', element: <QuizCreatorPage /> },
      { path: '/account-settings', element: <AccountSetting /> },
      { path: '/specialist/avaliation', element: <AdminReviewSpecialistAvaliation /> },
      { path: '/general/rating', element: <RatingPage /> },
      { path: '/user/registration', element: <RegistartionUserPage /> },
      { path: '/user/list', element: <AdminMunicipalyUserListPage /> },
      { path: '/my-works', element: <SpecialistWorksPage /> },
      { path: '/my-works/details', element: <WorkDetailsPage /> },
      { path: '/avaliation/list', element: <AvaliationListPage /> },
      { path: '/my-works/timeline', element: <ProjectSchedulePage /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: '/auth/404', element: <Error /> },
      { path: '/auth/account_problem', element: <AccountErrorProblem /> },
      { path: '/auth/login', element: <Login /> },
      { path: '/auth/login2', element: <Login2 /> },
      { path: '/auth/register', element: <Register /> },
      { path: '/auth/register/municipe', element: <RegisterPreExistUserPage /> },
      { path: '/auth/register2', element: <Register2 /> },
      { path: '/auth/forgot-password', element: <ForgotPassword /> },
      { path: '/auth/forgot-password2', element: <ForgotPassword2 /> },
      { path: '/auth/reset-password', element: <ResetPasswordPage /> },
      { path: '/auth/otp/validation', element: <OtpValidationPage /> },
      { path: '/auth/two-steps2', element: <TwoSteps2 /> },
      { path: '/auth/maintenance', element: <Maintenance /> },
      { path: '/landingpage1', element: <Landingpage /> },
      { path: '/landingpage', element: <LadingPageMain /> },
      { path: '/auth/account_validation', element: <AccountValidatePage /> },

      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;

import { setupInterceptorsTo } from './interceptors';
import axios from 'axios';

export const baseApiCall = setupInterceptorsTo(
  axios.create({
    // baseURL: 'http://127.0.0.1:8000/api/'
    // baseURL: 'http://162.0.237.160:4522/api/',
    baseURL: 'https://api.hrmanagement.speranza.co.mz/api/',
  }),
);

import { AxiosError, AxiosResponse } from "axios";
import { baseApiCall } from "../lib/api";
import { ActivityScheduleExempleInterface, AvaliationInterface, FormationAreaInterface, MunicipalyInterface, MunicipalyNecessityListInterface, NecessityActivityScheduleInterface, QuestionListInterface, SpecialistAvaliationDetailsInterface, SpecialistInterface, SpecialistWorkInterface, SupervisorInterface } from "src/types/management/tools";
import { CustomResponseInterface, CustomSpecialistResponseInterface, CustomUpdateResponseInterface, MunicipalyUserAndPermissionInterface, PageModuleInterface, UserInfo, UserNotificationSettingsInterface } from "src/types/auth/auth";
import { ERROR_REGISTE_FORMATION_AREA_TEXT, ERROR_SOMETHING_WENT_WRONG, ERROR_SOMETHING_WENT_WRONG_CONATCT_ADMIN } from "src/utils/constants/strings";
import { CustomMunicipalyNecessityListInterface, MunicipalyNecessity, SpecialistRatingsInterface, SpecialistSolicitationInterface, responseDataInterface } from "src/types/management/requests";
import { SelectFieldType } from "src/types/widget/Inputs";
import { SPECIALIST_AVALIATION_VALUE, SPECIALIST_INIT_VALUE } from "src/utils/constants/dicts";

interface formationRegisterProps {
    description: string
}

interface codeProps {
    code: string
}

interface specialistAvaliationDetailsProps{
    specialist_id:number
}

interface municipioCodeProps{
    municipio_code:string
}

interface locationProps {
    request_type: string
    code: string
}

interface FinishNecessityProps {
    necessity_id: number | undefined; 
    rate: number | null;
    comments: string
}

interface idProps {
    id: string
}

interface pageModuleTypeProps {
    type: string;
    userId: number
}

interface UpdateUserProfileInfoProps {
    request_type: string;
    first_name: string;
    last_name: string
    province: string
    bairro: string
    municipio: string
    phone: string
    municipio_description: string;
    bairro_description: string;
    bi_number: string
    email: string
}

interface UpdateUserProfilePasswordProps {
    request_type: string;
    current_password: string;
    new_password: string
}

interface UpdateUserNotificationSettingsProps {
    sms: boolean;
    email: boolean;
}

interface AnswerSpecialistSubmitionProps {
    specialistCode: string;
    answer: string;
}

interface aceptSpecialistProps {
    necessity_id:number,
    specialist_code:string | undefined
}

interface avarageRateProps{
    search_type: string | undefined;
    municpaly_code: string | undefined;
}

interface updateUserPermissionParams {
    new_permission: PageModuleInterface[];
    remove_permission: PageModuleInterface[];
    userId: number
}

interface requestSpecialistSolicitation {
    status: string;
    acept: boolean;
    solicitation_id: number | undefined
}

interface deleteSpecializationProps {
    list_code: string[],
    delete_all: boolean
}

interface supervisorRegistrationInterface {
    full_name: string,
    phone_number: string,
    municipio_code: string,
    email: string,
}
interface supervisorUpdatetionInterface {
    full_name: string,
    phone_number: string,
    municipio_code: string,
    email: string,
    status: string,
    request_type: string,
    supervisor_id: string
}

interface specialistRegisteInterface {
    address: string,
    description: string | undefined,
    email: string | undefined,
    phone_number: string | undefined,
    formation_area: SelectFieldType[],
    specialization: SelectFieldType[],
    municipality_code: string,
    municipioLocation: string;
    municipioLocation_code: string;
    bairro: string;
    province: string;
    bairro_code: string;
    specialist_code: string,
    is_company: boolean,
    supervisor_full_name: string,
    supervisor_phone: string,
    educational_instituition: string,
    bi_number: string
}

interface RegistrationMunicipalyUserParams {
    first_name: string,
    username: string,
    last_name: string,
    email: string,
    phone: string,
    password: string,
    is_staff: boolean,
    is_active: boolean,
    permission_type: string,
    page_module_list: [string]
}

interface RegistrationAdminMunicipalyUserParams {
    first_name: string,
    username: string,
    last_name: string,
    email: string,
    contact: string,
    password: string,
    is_staff: boolean,
    is_active: boolean,
    municipio_code: string,
    permission_type: string,
    page_module_list: string[]

}

interface QuizParams {
    question_name:string;
    specialization:string;
    answer_option_list: QuestionListInterface[] | undefined
}


interface AvaliationParams {
    specialization:string;
    question_qty:string;
    duration_min:string
}

export async function getAllFormationArea() {
    let formationsArea: FormationAreaInterface[] = []
    formationsArea = []
    try {
        await baseApiCall.get<FormationAreaInterface>('v1/formation/manager/').then((response: AxiosResponse) => {
            formationsArea = response.data;
        }).catch((error: AxiosError) => {
            console.log("Formacao ", error);
        });
    } catch (e) {
        return formationsArea
    }

    return formationsArea
}

export async function getAllEspecialization() {
    let formationsArea: FormationAreaInterface[] = []
    formationsArea = []
    try {
        await baseApiCall.get<FormationAreaInterface>('v1/specialization/manager/').then((response: AxiosResponse) => {
            formationsArea = response.data;
        }).catch((error: AxiosError) => {
            console.log("Formacao Errors ", error);
        });
    } catch (e) {
        return formationsArea
    }

    return formationsArea
}

export async function getAllNecessityTypes() {
    let necessityTypeList: FormationAreaInterface[] = []
    necessityTypeList = []
    try {
        await baseApiCall.get<FormationAreaInterface>('v1/municipaly_necessity_type/manager/').then((response: AxiosResponse) => {
            necessityTypeList = response.data;
        }).catch((error: AxiosError) => {
            console.log("Formacao Errors ", error);
        });
    } catch (e) {
        return necessityTypeList
    }

    return necessityTypeList
}

export async function registerNecessityType(params: formationRegisterProps) {
    let resp: CustomResponseInterface

    resp = { message: '', status: 0 }
    try {
        await baseApiCall.post('v1/municipaly_necessity_type/manager/', params).then((response: AxiosResponse) => {
            resp = response.data;
        }).catch((error) => {
            resp = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG, status: 400 };
        });
    } catch (e) {
    }

    return resp;
}

export async function getAllSectors() {
    let formationsArea: FormationAreaInterface[] = []
    formationsArea = []
    try {
        await baseApiCall.get<FormationAreaInterface>('v1/sectors/manager/').then((response: AxiosResponse) => {
            formationsArea = response.data;
        }).catch((error: AxiosError) => {
            console.log("Sectors Errors ", error);
        });
    } catch (e) {
        return formationsArea
    }

    return formationsArea
}

export async function registeFormationArea(params: formationRegisterProps) {
    let resp: CustomResponseInterface

    resp = { message: '', status: 0 }
    try {
        await baseApiCall.post('v1/formation/manager/', params).then((response: AxiosResponse) => {
            resp = { message: response.data['message'], status: response.status };
        }).catch((error: AxiosError) => {
            resp = { message: ERROR_REGISTE_FORMATION_AREA_TEXT, status: 400 };
            console.log("Formacao ", error);
        });
    } catch (e) {
        resp = { message: ERROR_REGISTE_FORMATION_AREA_TEXT, status: 400 };
    }

    return resp;
}

export async function registeSpecialization(params: formationRegisterProps) {
    let resp: CustomResponseInterface

    resp = { message: '', status: 0 }
    try {
        await baseApiCall.post('v1/specialization/manager/', params).then((response: AxiosResponse) => {
            resp = { message: response.data['message'], status: response.status };
        }).catch((error: AxiosError) => {
            resp = { message: ERROR_REGISTE_FORMATION_AREA_TEXT, status: 400 };
            console.log("Formacao ", error);
        });
    } catch (e) {
        resp = { message: ERROR_REGISTE_FORMATION_AREA_TEXT, status: 400 };
    }

    return resp;
}


export async function getSupervisor() {
    let supervisorList: SupervisorInterface[] = []

    supervisorList = []
    try {
        await baseApiCall.get<SupervisorInterface[]>('v1/supervisor/manager/').then((response: AxiosResponse) => {
            supervisorList = response.data;
        }).catch((error: AxiosError) => {
            console.log("Supervisor ", error);
        });
    } catch (e) {
    }

    return supervisorList;
}

export async function getMunicipio() {
    let municipioList: MunicipalyInterface[] = []

    municipioList = []
    try {
        await baseApiCall.get<MunicipalyInterface[]>('v1/municipio/manager/').then((response: AxiosResponse) => {
            municipioList = response.data;
        }).catch((error: AxiosError) => {
            console.log("Supervisor ", error);
        });
    } catch (e) {
    }

    return municipioList;
}

export async function registerMunicipio(params: formationRegisterProps) {
    let resp: CustomResponseInterface

    resp = { message: '', status: 0 }
    try {
        await baseApiCall.post('v1/municipio/manager/', params).then((response: AxiosResponse) => {
            resp = response.data;
        }).catch((error) => {
            resp = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG, status: 400 }
            console.log("Falha ao registrar municipio ", error)
            console.log("Supervisor ", error);
        });
    } catch (e) {
    }

    return resp;
}

export async function getSpecialistType() {
    let specialistTypeList: MunicipalyInterface[] = []

    specialistTypeList = []
    try {
        await baseApiCall.get<MunicipalyInterface[]>('v1/specialization/manager/type/').then((response: AxiosResponse) => {
            specialistTypeList = response.data;
        }).catch((error: AxiosError) => {
            console.log("Supervisor ", error);
        });
    } catch (e) {
    }

    return specialistTypeList;
}

export async function registeSpecialistType(params: formationRegisterProps) {
    let resp: CustomResponseInterface

    resp = { message: '', status: 0 }
    try {
        await baseApiCall.post('v1/specialization/manager/type/', params).then((response: AxiosResponse) => {
            resp = response.data;
        }).catch((error: AxiosError) => {
            console.log("Supervisor ", error);
        });
    } catch (e) {
    }

    return resp;
}

export async function registerSupervisor(params: supervisorRegistrationInterface) {
    let resp: CustomResponseInterface

    resp = { message: '', status: 0 }
    try {
        await baseApiCall.post('v1/supervisor/manager/', params).then((response: AxiosResponse) => {
            resp = { message: response.data.message, status: response.status };
        }).catch((error) => {
            resp = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG, status: 400 }
        });
    } catch (e) {
    }
    console.log("Veriy response", resp);

    return resp;
}

export async function updateOrDeleteSupervisor(params: supervisorUpdatetionInterface) {
    let resp: CustomResponseInterface

    resp = { message: '', status: 0 }
    try {
        await baseApiCall.post('v1/supervisor/manager/update_or_delete/', params).then((response: AxiosResponse) => {
            resp = { message: response.data.message, status: response.status };
        }).catch((error) => {
            resp = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG, status: 400 }
        });
    } catch (e) {
    }
    console.log("Veriy response", resp);

    return resp;
}

export async function registeSpecialist(params: specialistRegisteInterface) {
    let resp: CustomResponseInterface

    resp = { message: '', status: 0 }
    try {
        await baseApiCall.post('v1/specialist/manager/', params).then((response: AxiosResponse) => {
            resp = { message: response.data.message, status: response.status };
        }).catch((error) => {
            resp = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG, status: 400 }
        });
    } catch (e) {
    }

    return resp;
}

export async function verifySpecialistAccount() {
    let resp: CustomSpecialistResponseInterface

    resp = { exists: false, approved: false }
    try {
        await baseApiCall.get<CustomSpecialistResponseInterface>('v1/specialist/manager/').then((response: AxiosResponse) => {
            resp = response.data;
        }).catch((error) => {
            console.log("Error on verify Specialist ", error)
            resp = { exists: false, approved: false }
        });
    } catch (e) {
    }

    return resp;
}

export async function deleteSpecialization(props: deleteSpecializationProps) {
    let resp: CustomResponseInterface

    resp = { message: '', status: 0 }
    try {
        await baseApiCall.post('v1/specialization/manager/delete/', props).then((response: AxiosResponse) => {
            resp = { message: '', status: response.status };
        }).catch((error) => {
            resp = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG_CONATCT_ADMIN, status: 400 }
        });
    } catch (e) {
    }

    return resp;
}

export async function deleteFormationArea(props: deleteSpecializationProps) {
    let resp: CustomResponseInterface

    resp = { message: '', status: 0 }
    try {
        await baseApiCall.post('v1/formation/manager/delete/', props).then((response: AxiosResponse) => {
            resp = { message: '', status: response.status };
        }).catch((error) => {
            resp = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG_CONATCT_ADMIN, status: 400 }
        });
    } catch (e) {
    }

    return resp;
}

export async function deleteSpecializationType(props: deleteSpecializationProps) {
    let resp: CustomResponseInterface

    resp = { message: '', status: 0 }
    try {
        await baseApiCall.post('v1/specialization/manager/delete/type/', props).then((response: AxiosResponse) => {
            resp = { message: '', status: response.status };
        }).catch((error) => {
            resp = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG_CONATCT_ADMIN, status: 400 }
        });
    } catch (e) {
    }

    return resp;
}

export async function deleteCourse(props: deleteSpecializationProps) {
    let resp: CustomResponseInterface

    resp = { message: '', status: 0 }
    try {
        await baseApiCall.post('v1/course/manager/delete/', props).then((response: AxiosResponse) => {
            resp = { message: '', status: response.status };
        }).catch((error) => {
            resp = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG_CONATCT_ADMIN, status: 400 }
        });
    } catch (e) {
    }

    return resp;
}

export async function deleteMunicipio(props: deleteSpecializationProps) {
    let resp: CustomResponseInterface

    resp = { message: '', status: 0 }
    try {
        await baseApiCall.post('v1/municipio/manager/delete/', props).then((response: AxiosResponse) => {
            resp = { message: '', status: response.status };
        }).catch((error) => {
            resp = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG_CONATCT_ADMIN, status: 400 }
        });
    } catch (e) {
    }

    return resp;
}

export async function registeMunicipalyNecessity(props: MunicipalyNecessity) {
    let resp: CustomResponseInterface

    resp = { message: '', status: 0 }
    try {
        await baseApiCall.post('v1/municipaly_necessity/manager/', props).then((response: AxiosResponse) => {
            resp = response.data;
        }).catch((error) => {
            resp = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG_CONATCT_ADMIN, status: 400 }
        });
    } catch (e) {
    }

    return resp;
}

export async function registerSectors(params: formationRegisterProps) {
    let resp: CustomResponseInterface

    resp = { message: '', status: 0 }
    try {
        await baseApiCall.post('v1/sectors/manager/', params).then((response: AxiosResponse) => {
            resp = response.data;
        }).catch((error) => {
            resp = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG_CONATCT_ADMIN, status: 400 }
        });
    } catch (e) {
        return resp
    }

    return resp
}

export async function getAllMunicipalyNecessity() {
    let response: MunicipalyNecessityListInterface[] = []

    response = []
    try {
        await baseApiCall.get<MunicipalyNecessityListInterface>('v1/municipaly_necessity/manager/').then((resp: AxiosResponse) => {
            response = resp.data;
        }).catch((error) => {
            console.log("ERROR ON GETING NECESSITY MUNICIPIO ", error);
            response = [];
        });
    } catch (e) {
    }

    return response;
}

export async function registeMunicipalyUser(userCredentials: RegistrationMunicipalyUserParams) {
    let authResponse: CustomResponseInterface
    try {
        authResponse = { message: '', status: 0 }
        await baseApiCall.post('v1/municipio/regist_user/', userCredentials).then((response: AxiosResponse) => {
            authResponse = response.data;
        }).catch((error) => {
            authResponse = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG, status: 400 };
        });

        return authResponse
    } catch (e) {
        return { message: 'Could not verify your credentials, please check your internet connection and try again.', status: 400 }
    }
}

export async function getMunicipalyUser() {
    let userInfo: UserInfo[] = []
    userInfo = []
    try {
        await baseApiCall.get('v1/municipio/users/manager/').then((response: AxiosResponse) => {
            userInfo = response.data;
        }).catch((error) => {
            console.error("Filed wen get user ", error);
        });
    } catch (e) {

        return []
    }

    return userInfo
}

export async function getMunicipalyUserWithPermission() {
    let userPermissionInfo: MunicipalyUserAndPermissionInterface[] = []
    userPermissionInfo = []
    try {
        await baseApiCall.get<MunicipalyUserAndPermissionInterface>('v1/municipio/users_permission/manager/').then((response: AxiosResponse) => {
            userPermissionInfo = response.data;
        }).catch((error) => {
            console.error("Filed wen get user ", error);
        });
    } catch (e) {

        return []
    }

    return userPermissionInfo
}

export async function getSingleMunicipalyUser(params: idProps) {
    let userPermissionInfo: MunicipalyUserAndPermissionInterface
    userPermissionInfo = {
        email: '',
        id: 0,
        is_staff: false,
        is_superuser: false,
        username: '',
        first_name: '',
        last_name: '',
        full_name: '',
        contact:'',
        profile_image:'',
        is_active: false,
        userpermissionmodel: {
            municipio:
            {
                code: '',
                description: ''
            },
            page_module: [
                {
                    id: 0,
                    module: '',
                    description: '',
                    type: ''
                }
            ],
            permission: '',
        }
    }
    try {
        await baseApiCall.post('v1/municipio/users/manager/', params).then((response: AxiosResponse) => {
            userPermissionInfo = response.data;
        }).catch((error) => {
            console.error("Filed wen get user ", error);
        });
    } catch (e) {

        return userPermissionInfo
    }

    return userPermissionInfo
}


export async function getPageModuleByType(params: pageModuleTypeProps) {
    let pageModuleInfo: PageModuleInterface[] = []

    try {
        await baseApiCall.post<PageModuleInterface>('account/v1/permission/module/manager/', params).then((response: AxiosResponse) => {
            pageModuleInfo = response.data;
        }).catch((error) => {
            console.error("Filed wen get user ", error);
        });
    } catch (e) {

        return pageModuleInfo
    }

    return pageModuleInfo
}

export async function updateUserPermission(params: updateUserPermissionParams) {
    let resp: CustomUpdateResponseInterface
    resp = { message: '', status: 0, updated: false }
    try {
        await baseApiCall.post('account/v1/permission/update/', params).then((response: AxiosResponse) => {
            resp = response.data;
        }).catch((error) => {
            console.error("Filed wen get user ", error);
        });
    } catch (e) {

        return resp
    }

    return resp
}

export async function getSpecialistSolicitation() {
    let solicitation: SpecialistSolicitationInterface[] = []
    solicitation = []
    try {
        await baseApiCall.get('v1/specialist/solicitation/manager/').then((response: AxiosResponse) => {
            solicitation = response.data;
        }).catch((error) => {
            console.error("Filed wen get user ", error);
        });
    } catch (e) {

        return solicitation
    }

    return solicitation
}

export async function getsingleMunicipalyNecessity(props: idProps) {
    let solicitation: CustomMunicipalyNecessityListInterface | undefined
    solicitation = undefined
    try {
        await baseApiCall.post('v1/municipaly_necessity_filter/manager/', props).then((response: AxiosResponse) => {
            solicitation = response.data;
        }).catch((error) => {
            console.error("Filed wen get user ", error);
        });
    } catch (e) {

        return solicitation
    }

    return solicitation
}

export async function sendSpecialisSolicitationMunicipalyNecessityResponse(props: requestSpecialistSolicitation) {
    let resp: CustomUpdateResponseInterface
    resp = { message: '', status: 0, updated: false }
    try {
        await baseApiCall.post('v1/specialist/solicitation/manager/', props).then((response: AxiosResponse) => {
            resp = { message: '', status: response.status, updated: false };
        }).catch((error) => {
            console.error("Filed wen get user ", error);
        });
    } catch (e) {

        return resp
    }

    return resp
}

export async function getSpecialistSingleSolicitation(props: idProps) {
    let solicitation: SpecialistSolicitationInterface
    solicitation = {
        approval_track: {
            status: '',
            track_description: ''
        },
        created_at: '',
        id: 0,
        necessity: {
            area: {
                code: '',
                description: ''
            },
            description: '',
            id: 0,
            municipio: {
                code: '',
                description: ''
            },
            necessity: {
                code: '',
                description: ''
            },
            sector: {
                code: '',
                description: ''
            },
            status: ''
        },
        specialist: 0,
        status: ''
    }
    try {
        await baseApiCall.post('v1/specialist/single_solicitation/manager/', props).then((response: AxiosResponse) => {
            solicitation = response.data;
        }).catch((error) => {
            console.error("Filed wen get user ", error);
        });
    } catch (e) {

        return solicitation
    }

    return solicitation
}

export async function getCourses() {
    let coursedList: MunicipalyInterface[] = []

    coursedList = []
    try {
        await baseApiCall.get<MunicipalyInterface[]>('v1/course/manager/').then((response: AxiosResponse) => {
            coursedList = response.data;
        }).catch((error: AxiosError) => {
            console.log("ERROR ON GET COURSES ", error);
        });
    } catch (e) {
    }

    return coursedList;
}

export async function registeCourse(params: formationRegisterProps) {
    let resp: CustomResponseInterface

    resp = { message: '', status: 0 }
    try {
        await baseApiCall.post('v1/course/manager/', params).then((response: AxiosResponse) => {
            resp = response.data;
        }).catch((error) => {
            resp = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG, status: 400 };
        });
    } catch (e) {
    }

    return resp;
}

export async function getProvinces() {
    let coursedList: MunicipalyInterface[] = []

    coursedList = []
    try {
        await baseApiCall.get<MunicipalyInterface[]>('v1/province/manager/').then((response: AxiosResponse) => {
            coursedList = response.data;
        }).catch((error: AxiosError) => {
            console.log("ERROR ON GET PROVINCES ", error);
        });
    } catch (e) {
    }

    return coursedList;
}

export async function getMunicipioLocation(params: locationProps) {
    let resp: responseDataInterface

    resp = { data: {}, status: 0, message: '' }
    try {
        await baseApiCall.post('v1/location/manager/', params).then((response: AxiosResponse) => {
            resp = { data: response.data, status: response.status, message: '' };
        }).catch((error) => {
            resp = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG, status: 400, data: {} };
        });
    } catch (e) {
    }

    return resp;
}

export async function getBairroLocation(params: locationProps) {
    let resp: responseDataInterface

    resp = { data: {}, status: 0, message: '' }
    try {
        await baseApiCall.post('v1/location/manager/', params).then((response: AxiosResponse) => {
            resp = { data: response.data, status: response.status, message: '' };
        }).catch((error) => {
            resp = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG, status: 400, data: {} };
        });
    } catch (e) {
    }

    return resp;
}

export async function finishNecessity(params: FinishNecessityProps) {
    let resp: CustomUpdateResponseInterface
    resp = { message: '', status: 0, updated: false }
    try {
        await baseApiCall.post('v1/municipaly_necessity/finish/', params).then((response: AxiosResponse) => {
            resp = response.data;
        }).catch((error) => {
            resp = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG, status: 400, updated: false };
        });
    } catch (e) {
    }

    return resp;
}

export async function getEducationalInstitution() {
    let institution: MunicipalyInterface[] = []

    institution = []
    try {
        await baseApiCall.get<MunicipalyInterface[]>('v1/educational_institution/manager/').then((response: AxiosResponse) => {
            institution = response.data;
        }).catch((error: AxiosError) => {
            console.log("ERROR ON GET EDUCATIONAL INSTITUTION ", error);
        });
    } catch (e) {
    }

    return institution;
}

export async function registeEducationalInstitution(params: formationRegisterProps) {
    let resp: CustomResponseInterface

    resp = { message: '', status: 0 }
    try {
        await baseApiCall.post('v1/educational_institution/manager/', params).then((response: AxiosResponse) => {
            resp = { message: response.data['message'], status: response.status };
        }).catch((error) => {
            resp = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG, status: 400 };
            console.log("Formacao ", error);
        });
    } catch (e) {
        resp = { message: ERROR_REGISTE_FORMATION_AREA_TEXT, status: 400 };
    }

    return resp;
}

export async function deleteEducationalInstitution(props: deleteSpecializationProps) {
    let resp: CustomResponseInterface

    resp = { message: '', status: 0 }
    try {
        await baseApiCall.post('v1/educational_institution/delete/manager/', props).then((response: AxiosResponse) => {
            resp = { message: '', status: response.status };
        }).catch((error) => {
            resp = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG_CONATCT_ADMIN, status: 400 }
        });
    } catch (e) {
    }

    return resp;
}

export async function getAllSpecialistCandidacy() {
    let specialist: SpecialistInterface[] = []

    specialist = []
    try {
        await baseApiCall.get<SpecialistInterface[]>('v1/specialist/candidacy/manager/').then((response: AxiosResponse) => {
            specialist = response.data;
        }).catch((error: AxiosError) => {
            console.log("ERROR ON GET SPECIALISTS ", error);
        });
    } catch (e) {
    }

    return specialist;
}


export async function getSingleSpecialistCandidacy(props:codeProps) {
    let specialist: SpecialistInterface

    specialist = SPECIALIST_INIT_VALUE
    try {
        await baseApiCall.post<SpecialistInterface>('v1/specialist/candidacy/manager/', props).then((response: AxiosResponse) => {
            specialist = response.data;
        }).catch((error: AxiosError) => {
            console.log("ERROR ON GET SPECIALISTS ", error);
        });
    } catch (e) {
    }

    return specialist;
}


export async function updateProfile(params: FormData) {
    let resp: CustomUpdateResponseInterface
    resp = { message: '', status: 0, updated: false }
    try {
        await baseApiCall.post('account/v1/profile/update/manager/', params).then((response: AxiosResponse) => {
            resp = response.data;
        }).catch((error) => {
            resp = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG, status: 400, updated: false };
        });
    } catch (e) {
    }

    return resp;
}

export async function updateProfileInfo(params: UpdateUserProfileInfoProps) {
    let resp: CustomUpdateResponseInterface
    resp = { message: '', status: 0, updated: false }
    try {
        await baseApiCall.post('account/v1/profile/update/manager/', params).then((response: AxiosResponse) => {
            resp = response.data;
        }).catch((error) => {
            resp = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG, status: 400, updated: false };
        });
    } catch (e) {
    }

    return resp;
}

export async function updateProfilePassword(params: UpdateUserProfilePasswordProps) {
    let resp: CustomUpdateResponseInterface
    resp = { message: '', status: 0, updated: false }
    try {
        await baseApiCall.post('account/v1/profile/update/manager/', params).then((response: AxiosResponse) => {
            resp = response.data;
        }).catch((error) => {
            resp = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG, status: 400, updated: false };
        });
    } catch (e) {
    }

    return resp;
}

export async function getUserNotificationSettigns() {
    let specialist: UserNotificationSettingsInterface

    specialist = {
        email_notification: false,
        sms_notification: false,
        user: 0
    }
    try {
        await baseApiCall.get<SpecialistInterface[]>('account/v1/notification_settings/manager/').then((response: AxiosResponse) => {
            specialist = response.data;
        }).catch((error: AxiosError) => {
            console.log("ERROR ON GET USER NOTIFICATION SETTINGS ", error);
        });
    } catch (e) {
    }

    return specialist;
}

export async function updateUserNotificationSettings(params: UpdateUserNotificationSettingsProps) {
    let resp: CustomUpdateResponseInterface
    resp = { message: '', status: 0, updated: false }
    try {
        await baseApiCall.post('account/v1/notification_settings/manager/', params).then((response: AxiosResponse) => {
            resp = response.data;
        }).catch((error) => {
            resp = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG, status: 400, updated: false };
        });
    } catch (e) {
    }

    return resp;
}

export async function startQuiz() {
    let resp: CustomUpdateResponseInterface
    resp = { message: '', status: 0, updated: false }
    try {
        await baseApiCall.post('v1/quiz/manager/').then((response: AxiosResponse) => {
            resp = response.data;
        }).catch((error) => {
            resp = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG, status: 400, updated: false };
        });
    } catch (e) {

        return resp
    }

    return resp
}

export async function answerSpecialistSubmition(props:AnswerSpecialistSubmitionProps) { 
    let resp: CustomUpdateResponseInterface = { message: '', status: 0, updated: false }; 
    try { 
        await baseApiCall.post('v1/admin/specialist_submition/manager/', props).then((response: AxiosResponse) => { 
            resp = response.data; 
        }).catch((error) => { 
            resp = { 
                message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG, status: 400, updated: false }; 
            }); 
        } catch (e) { 

            return resp 
        } 

    return resp 
}

export async function aceptSpecialistToWork(props:aceptSpecialistProps) { 
    let resp: CustomUpdateResponseInterface = { message: '', status: 0, updated: false }; 
    try { 
        await baseApiCall.post('v1/municipio/acept_specialist/manager/', props).then((response: AxiosResponse) => { 
            resp = response.data; 
        }).catch((error) => { 
            resp = { 
                message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG, status: 400, updated: false }; 
            }); 
        } catch (e) { 

            return resp 
        } 

    return resp 
}

export async function getActiveNecessity(props:aceptSpecialistProps) { 
    let resp: SpecialistWorkInterface | undefined; 
    try { 
        await baseApiCall.post('v1/municipio/active_necessity/manager/', props).then((response: AxiosResponse) => { 
            resp = response.data; 
        }).catch((error) => { 
            console.log(error);
            resp = undefined;
            }); 
        } catch (e) { 
            return resp 
        } 

    return resp 
}

export async function getAvarageRates(props:avarageRateProps) { 
    let resp: SpecialistRatingsInterface[] = []; 
    try { 
        await baseApiCall.post('v1/rating/manager/', props).then((response: AxiosResponse) => { 
            resp = response.data; 
        }).catch((error) => { 
            console.log(error);
            resp = []
            }); 
        } catch (e) { 
            return resp 
        } 

    return resp 
}

export async function getSpecialistAvaliationDetails(props:specialistAvaliationDetailsProps) {
    let specialistAvaliation: SpecialistAvaliationDetailsInterface = SPECIALIST_AVALIATION_VALUE

    try {
        await baseApiCall.post<SpecialistInterface>('v1/admin/specialist_avaliation/manager/', props).then((response: AxiosResponse) => {
            specialistAvaliation = response.data;
        }).catch((error: AxiosError) => {
            console.log("ERROR ON GET SPECIALISTS ", error);
        });
    } catch (e) {
        return specialistAvaliation
    }

    return specialistAvaliation;
}

export async function registeAdminMunicipalyUser(userCredentials: RegistrationAdminMunicipalyUserParams) {
    let authResponse: CustomResponseInterface
    try {
        authResponse = { message: '', status: 0 }
        await baseApiCall.post('v1/admin/municipaly_user/register/', userCredentials).then((response: AxiosResponse) => {
            authResponse = response.data;
        }).catch((error) => {
            authResponse = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG, status: 400 };
        });

        return authResponse
    } catch (e) {
        return { message: 'Could not verify your credentials, please check your internet connection and try again.', status: 400 }
    }
}

export async function getAdminMunicipalyUser(data: municipioCodeProps) {
    let userInfo: MunicipalyUserAndPermissionInterface[] = []
    try {
        await baseApiCall.post('v1/admin/municipaly_user/manager/', data).then((response: AxiosResponse) => {
            userInfo = response.data;
        }).catch((error) => {
            console.log("Fieled on get User", error);
        });
    } catch (e) {
        return userInfo
    }

    return userInfo
}

export async function createQuizQuestionary(data: QuizParams) {
    let authResponse: CustomResponseInterface
    try {
        authResponse = { message: '', status: 0 }
        await baseApiCall.post('v1/admin/quiz_creator/manager/', data).then((response: AxiosResponse) => {
            authResponse = response.data;
        }).catch((error) => {
            authResponse = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG, status: 400 };
        });

        return authResponse
    } catch (e) {
        return { message: 'Could not verify your credentials, please check your internet connection and try again.', status: 400 }
    }
}

export async function getSpecialistWorks() {
    let specialist: SpecialistWorkInterface[] = []

    try {
        await baseApiCall.get<SpecialistWorkInterface[]>('v1/specialist/works/manager/').then((response: AxiosResponse) => {
            specialist = response.data;
        }).catch((error: AxiosError) => {
            console.log("ERROR ON GET USER NOTIFICATION SETTINGS ", error);
        });
    } catch (e) {
    }

    return specialist;
}


export async function getAllAvaliation() {
    let avaliations: AvaliationInterface[] = []
    avaliations = []
    try {
        await baseApiCall.get<AvaliationInterface>('v1/admin/avaliation/manager/').then((response: AxiosResponse) => {
            avaliations = response.data;
        }).catch((error: AxiosError) => {
            console.log("Formacao Errors ", error);
        });
    } catch (e) {
        return avaliations
    }

    return avaliations
}

export async function createAvaliation(data: AvaliationParams) {
    let authResponse: CustomResponseInterface
    try {
        authResponse = { message: '', status: 0 }
        await baseApiCall.post('v1/admin/avaliation/manager/', data).then((response: AxiosResponse) => {
            authResponse = response.data;
        }).catch((error) => {
            authResponse = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG, status: 400 };
        });

        return authResponse
    } catch (e) {
        return { message: 'Could not verify your credentials, please check your internet connection and try again.', status: 400 }
    }
}


export async function getNecessityActivitySchedule(data:{necessity_id:number}) {
    let necessityActivitySchedule: NecessityActivityScheduleInterface[] = []
    try {
        await baseApiCall.get<NecessityActivityScheduleInterface>('v1/admin/necessity_activity_schedule/manager/', {params:data}).then((response: AxiosResponse) => {
            necessityActivitySchedule = response.data;
        }).catch((error: AxiosError) => {
            console.log("getNecessityActivitySchedule Errors ", error);
        });
    } catch (e) {
        return necessityActivitySchedule
    }

    return necessityActivitySchedule
}

export async function uploadNecessityActivitySchedule(data: FormData) {
    let authResponse: CustomResponseInterface
    try {
        authResponse = { message: '', status: 0 }
        await baseApiCall.post('v1/admin/necessity_activity_schedule/manager/', data).then((response: AxiosResponse) => {
            authResponse = response.data;
        }).catch((error) => {
            authResponse = { message: error.response?.data.message || ERROR_SOMETHING_WENT_WRONG, status: 400 };
        });

        return authResponse
    } catch (e) {
        return { message: 'Could not verify your credentials, please check your internet connection and try again.', status: 400 }
    }
}

export async function getActivityScheduleExemple() {
    let activityScheduleExemple: ActivityScheduleExempleInterface = {
        schedule_exemple_file:''
    }
    try {
        await baseApiCall.get<ActivityScheduleExempleInterface>('v1/admin/activity_schedule_exemple/manager/').then((response: AxiosResponse) => {
            activityScheduleExemple = response.data;
        }).catch((error: AxiosError) => {
            console.log("getNecessityActivitySchedule Errors ", error);
        });
    } catch (e) {
        return activityScheduleExemple
    }

    return activityScheduleExemple
}
import React, { useEffect } from 'react';
import { alpha } from '@mui/material/styles';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  TextField,
  InputAdornment,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { FATableType } from "src/components/tables/tableData";
import { IconSearch } from '@tabler/icons';
import PageContainer from 'src/components/container/PageContainer';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import ParentCard from 'src/components/shared/ParentCard';
import BlankCard from 'src/components/shared/BlankCard';
import CustomCheckbox from 'src/components/forms/theme-elements/CustomCheckbox';
import { getProvinces } from 'src/Settings/requests/management';
import { MunicipalyInterface } from 'src/types/management/tools';
import Spinner from 'src/views/spinner/Spinner';

const BCrumb = [
    {
      to: '/dashboards/modern',
      title: 'Painel',
    },
    {
      title: 'Categorias',
    },
  ];
  
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  
    return 0;
  }
  
  type Order = 'asc' | 'desc';
  
  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
  ): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function stableSort<T>(array: any[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
  
      return a[1] - b[1];
    });
  
    return stabilizedThis.map((el) => el[0]);
  }
  
  interface HeadCell {
    disablePadding: boolean;
    id: any;
    label: string;
    numeric: boolean;
  }
  
  const headCells: HeadCell[] = [
    {
      id: 'pname',
      numeric: false,
      disablePadding: false,
      label: 'Code',
    },
    {
      id: 'team',
      numeric: false,
      disablePadding: false,
      label: 'Descrição',
    },
  ];

  interface EnhancedTableToolbarProps {
    numSelected: number;
    handleSearch: React.ChangeEvent<HTMLInputElement> | any;
    search: string;
  }

export default function ProvincePage(){
    const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<any>('calories');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [deleteSelected, setDeletedSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [search, setSearch] = React.useState('');
  const [provincesTable, setProvincesTable] = React.useState<FATableType[]>([]);
  useEffect(() => {loadFirstContent()},[])

  async function loadFirstContent(){
    setIsLoading(true);
    const provinceList = await getProvinces();
    const tableData:FATableType[] = provinceList.map((item:MunicipalyInterface) => {return {
      name:item.description,
      id:item.code,
    }})
    setProvincesTable(tableData);
    setIsLoading(false);
  }
  
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof []) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = provincesTable.map((n) => n.name);
      setSelected(newSelecteds);

      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string, code:string) => {
    const selectedIndex = selected.indexOf(name);
    const newList = deleteSelected.concat(code);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setDeletedSelected(newList);
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


//   async function handleDeleteTableItem() {
//     setCustomLoading(true);
//     const resp = await deleteCourse({
//       delete_all:deleteAllTableItem,
//       list_code:deleteSelected
//     });

//     if (resp.status != 200){
//       setMessage(resp.message);
//       setisError(true);
//     }else{
//       setisError(false);
//     }
//     setDeletedSelected([]);
//     setSelected([]);
//     loadFirstContent();
//     setDeleteAllTableItem(false);
//     setCustomLoading(false);
//   };


  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - provincesTable.length) : 0;

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    // const filteredRows: ProductType[] = getProducts.filter((row) => {
    //   return row.title.toLowerCase().includes(event.target.value);
    // });
    setSearch(event.target.value);

    // setRows(filteredRows);
  };


 

  const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const { numSelected, handleSearch, search } = props;
    
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle2" component="div">
            {numSelected} selected
          </Typography>
        ) : (
          <Box sx={{ flex: '1 1 100%' }}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconSearch size="1.1rem" />
                  </InputAdornment>
                ),
              }}
              placeholder="Pesquisar"
              size="small"
              onChange={handleSearch}
              value={search}
            />
          </Box>
        )}
        {
          numSelected > 0 ? (
            <></>

            // <Tooltip onClick={() => handleDeleteTableItem()} title="Apagar">
            //   <IconButton>
            //     <IconTrash width="18" />
            //   </IconButton>
            // </Tooltip>

          ) : (
            <></>

            // <Tooltip onClick={() => handleOpenFormationModal()} title="Adicionar especialização">
            //   <IconButton>
            //     <IconSquarePlus size="1.2rem" />
            //   </IconButton>
            // </Tooltip>

          ) }
      </Toolbar>
    );
  };

    return (
        <PageContainer title="Províncias" description="esta é a área das Especializações">
      {/* breadcrumb */}
      <Breadcrumb title="Tabela das Províncias" items={BCrumb} />
      {isLoading ? <Spinner /> : (
        <>
            <ParentCard title="Províncias">
        <>  
          {/* {isError && <>
            <Alert variant='filled' severity="error" onClose={() => {
                setisError(false);
                setDeletedSelected([]);
                setSelected([]);
                setDeleteAllTableItem(false);
                setCustomLoading(false);
              }}>
            {message}
          </Alert>
          <br/>
          </>} */}
          <BlankCard>
            <Box mb={2} sx={{ mb: 2 }}>
              <EnhancedTableToolbar 
                numSelected={selected.length}
                search={search}
                handleSearch={(event: any) => handleSearch(event)} />
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={'medium'}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={provincesTable.length}
                  />
                  <TableBody>
                    {stableSort(provincesTable, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row: any, index) => {
                        const isItemSelected = isSelected(row.name);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.name, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <CustomCheckbox
                                checked={isItemSelected}
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <Typography color="textSecondary" variant="subtitle2" fontWeight="400">
                                {row.id}
                              </Typography>
                            </TableCell>
                            
                            
                            <TableCell>
                              <Typography color="textSecondary" variant="body1">
                                {row.name}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={provincesTable.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          
          </BlankCard>
        </>
      </ParentCard>
      {/* <CourseRegistrationModal 
        open={openRegistrationModal} 
        getTableData={loadFirstContent}
        setOpen={setOpenRegistrationModal} /> */}
        </>
      )}
    </PageContainer>
    );
}


interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof []) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property: keyof []) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <CustomCheckbox
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              tabIndex={-1}
              inputProps={{
                'aria-labelledby': 'select all desserts',
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography variant="subtitle1" fontWeight="500">
                  {headCell.label}
                </Typography>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }